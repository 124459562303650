import React, { FC } from 'react'
import { Card as CardMUI, CardContent, Typography } from '@mui/material'

import { CardProps } from './types'

const Card: FC<CardProps> = ({ title, subtitle }) => {
    return (
        <CardMUI>
            <CardContent>
                <Typography variant='h3Bold' color="secondary">
                    {title}
                </Typography>
                <Typography variant='h6'>
                    {subtitle}
                </Typography>
            </CardContent>
        </CardMUI>
    )
}

export default Card