import { FC, useContext } from 'react';

import { TrackingContext } from '@context/index';
import { TrackingStatus } from '@context/types';

import { Grid, Typography } from '@mui/material';
import { getTrackingMessage } from '@utils/tracking-messages';

import styles from './styles.module.scss'
import { Link } from 'react-router-dom';

const TrackingDetailStatus: FC = () => {
    const { state } = useContext(TrackingContext)
    const { trackingData } = state
    if (!trackingData) return null

    const currentState = trackingData[trackingData.length - 1]

    return (
        <Grid container spacing={2} direction="column" alignItems="flex-start" className={styles.TrackingDetailStatus}>
            {currentState?.codigo &&
                <Grid item>
                    <Typography variant="h2Bold">
                        {getTrackingMessage(currentState.codigo)}
                    </Typography>
                </Grid>
            }
            {currentState?.mensaje &&
                (currentState.codigo === TrackingStatus.ENTR ?
                    <Grid item>
                        <Typography>
                            {currentState.mensaje} el día
                            <Typography variant="body1Bold">
                                {` ${currentState.fecha}`}
                            </Typography>
                        </Typography>
                    </Grid>
                    :
                    <Grid item>
                        <Typography>
                            {currentState.mensaje}
                        </Typography>
                    </Grid>
                )
            }
            <Grid item container direction="column" alignItems="flex-start" className={styles.TrackingDetailStatus__controls}>
                <Link to="/tracking" className={styles.TrackingDetailStatus__link}>Buscar otro código</Link>
            </Grid>

        </Grid>
    )
}

export default TrackingDetailStatus