import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';

import logo from '@assets/logo.png'

const Footer = () => {
    return (
        <Grid className={styles.Footer}>
            <Grid container className={styles.Footer__Wrapper}>
                <Grid item>
                    <Typography variant="h3Bold" color="var(--blue700)" align="center">
                        Soluciones logísticas <br /> para tu ecommerce
                    </Typography>
                </Grid>
                <Grid item className={styles.Footer__Links}>
                    <HashLink to="/#conocenos">
                        Acerca de Rayo
                    </HashLink>
                    <HashLink to="/#servicios">
                        ¿Qué ofrecemos?
                    </HashLink>
                    <Link to="faq">
                        Preguntas frecuentes
                    </Link>
                </Grid>
                <Grid item className={styles.Footer__Logo}>
                    <img src={logo} alt="logo" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer