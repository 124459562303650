import { cSSVariablesService } from "../../../../utils/css-variables";

const background = cSSVariablesService.get('--green500');
const green700 = cSSVariablesService.get('--green700');
const green700Hover = cSSVariablesService.get('--green700-hover');

const light = cSSVariablesService.get('--color-bg');

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
    containedLight: true
  }
}

export const button = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'containedLight' },
        style: {
          textTransform: 'none',
          background: green700,
          '&:hover': {
            backgroundColor: green700Hover,
          }
        },
      },
    ],
    styleOverrides: {
      root: {
        backgroundColor: background,
        color: light,
        padding: 'var(--padding-button)',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: 'rgb(27, 121, 67)',
        },
        '@media (max-width: 600px)': { // md
          fontSize: '1rem'
        }
      },
      outlined: {
        backgroundColor: background,
      },
      contained:{
        backgroundColor: 'var(--blue500)',
        '&:hover': {
          backgroundColor: 'var(--blue500-hover)',
        },
      },
      link: {
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
        },
      },
    }
  }
}