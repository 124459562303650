import React from 'react'

import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import styles from './styles.module.scss'
import Carrousel from '@molecules/Carrousel'

const Servicios = () => {
    return (
        <Grid id="servicios" container className={styles.Servicios} direction="column" justifyContent="center" alignContent="center">
            <Grid item alignItems="center" display="flex" justifyContent="center">
                <Typography variant="h3Bold" color="white" align="center">
                    Confían en nosotros
                </Typography>
            </Grid>
            <Grid item className={styles.Servicios__Items}>
                <Carrousel
                    slidesPerView={3}
                    spaceBetween={50}
                    loop
                    centeredSlides={false}
                    navigation={false}
                    className={styles.Servicios__Carrousel}
                    autoplay
                    breakpoints={{
                        768: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                            navigation: false
                        }
                    }}
                >
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/1.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/2.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/3.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/4.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/5.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/6.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/7.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/8.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/9.png" alt="brand"/></Box>
                    <Box className={styles.Servicios__Item}><img src="./assets/images/brands/10.png" alt="brand"/></Box>
                </Carrousel>
            </Grid>
        </Grid>
    )
}

export default Servicios