declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
  }
}

export const accordion = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: 'none',
        borderBottom: '1px solid #4F4F4F',
        borderBottomLeftRadius: '0px !important',
        borderBottomRightRadius: '0px !important',
      },
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 'fit-content !important',
        margin: '1rem 0',
        '@media (max-width: 600px)': {
          margin: '0'
        }
      },
      content: {
        margin: '0 !important'
      },
    }
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        marginBottom: '2rem !important',
      }
    }
  }
}