
export const textField = {
    MuiInputLabel: {
        styleOverrides: {
            root: {
                fontFamily: 'var(--font)',
                fontSize: 'var(--font-h6)',
                fontWeight: 400
            },
        }
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                borderRadius: '8px',
                fontFamily: 'var(--font)',
                fontSize: 'var(--font-h6)',
                fontWeight: 400,
                '&:hover': {
                    borderBottom: 'none',
                    '&:before': {
                        borderBottom: '0 !important',
                    },
                },
                '&:before': {
                    borderBottom: 0,
                },
                '&:after': {
                    display: "none"
                },

            },
        }

    }
}