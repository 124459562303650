import React, { useReducer } from 'react'
import { TrackingReducer } from './reducer';
import { TrackingContextType } from './types';


const initialState = {
  trackingData: [],
  loading: false,
  error: "",
  transition: true
}

export const TrackingContext = React.createContext<{
  state: TrackingContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null
})


export const TrackingProvider: React.FunctionComponent = ({ children }: any) => {
  const [state, dispatch] = useReducer(TrackingReducer, initialState);
  return (
    <TrackingContext.Provider value={{ state, dispatch }}>
      {children}
    </TrackingContext.Provider>
  )
}