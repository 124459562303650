import { TrackingContext } from '@context/index'
import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { getTrackingMessage } from '@utils/tracking-messages';

import styles from './styles.module.scss'
import check from '@assets/icons/check.svg'


const TrackingSteps = () => {
    const { state: { trackingData } } = useContext(TrackingContext)
    if(!trackingData.length) return null
    
    return (
        <Grid container direction="column" className={styles.TrackingSteps} spacing={2}>
            <Grid item className={styles.TrackingSteps__Title}>
                <Typography variant='h3Bold'>
                    Seguimiento de tu envío
                </Typography>
            </Grid>
            <Grid item className={styles.TrackingSteps__Trackings}>
                <Grid container direction="column">
                    {[...trackingData].reverse().map((item, index) => {
                        return (
                            <Box className={styles.TrackingSteps__item} key={index}>
                                <Box className={styles.TrackingSteps__lineContainer}>
                                    <img src={check} alt="check" className={styles.TrackingSteps__icon} />
                                    <div className={styles.TrackingSteps__line}></div>
                                </Box>
                                <Grid className={styles.TrackingSteps__itemWrapper}>
                                    <Grid className={styles.TrackingSteps__item__header}>
                                        <Typography variant="h5Bold" color="#4D4D4D">
                                            {getTrackingMessage(item.codigo)}
                                        </Typography>
                                        <Typography variant="caption" color="var(--gray100)">
                                            {item.fecha}
                                        </Typography>
                                    </Grid>
                                    <Typography>
                                        {item.mensaje}
                                    </Typography>
                                </Grid>
                            </Box>
                        )
                    })}
                </Grid>
            </Grid>

        </Grid>
    )
}

export default TrackingSteps