import MainWrapper from '@organisms/MainWrapper';
import React, { FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TrackingDetailStatus from './components/TrackingDetailStatus';
import { TrackingContext } from '../../../context';

import styles from './styles.module.scss'
import TrackingSteps from './components/TrackingSteps';
import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const TrackingDetail: FC = () => {
    const { id } = useParams()
    const { state, dispatch } = useContext(TrackingContext)
    let navigate = useNavigate();

    const fetchData = async () => {
        dispatch({ type: 'SET_LOADING', payload: true })

        try {
            const data = await fetch(`${process.env.REACT_APP_ENVIOPACK_URL}/tracking?tracking_number=${id}`)
            const res: any = await data.json()
            dispatch({ type: 'SET_TRACKING_DATA', payload: res[0]?.tracking })
            dispatch({ type: 'SET_LOADING', payload: false })
        } catch (err) {
            dispatch({ type: 'SET_LOADING', payload: false })
            dispatch({ type: 'SET_ERROR', payload: "Ocurrió un error, inténtelo nuevamente" })
        }
    }
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = () => {
        navigate(0)
    }

    return (
        <MainWrapper className={styles.TrackingDetail} hasPadding loading={state.loading}>
            <>
                {
                    !state.error ?
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4}>
                                <TrackingDetailStatus />
                            </Grid>
                            <Grid item lg={6} flex={1}>
                                <TrackingSteps />
                            </Grid>
                        </Grid>
                        :
                        <Grid xs={6} justifyContent="center" alignItems="center" container spacing={4} direction="column" gap={3}>
                            <Typography variant="h5Bold" textAlign="center">{state.error}</Typography>
                            <Button onClick={handleClick} > Intentar nuevamente</Button >
                            <Link to="/tracking" className={styles.TrackingDetail__link}>Buscar otro código</Link>
                        </Grid>
                }
            </>
        </MainWrapper >
    )
}

export default TrackingDetail