export const FAQ = [
    {
        title: '¿Qué servicio ofrecemos?',
        content: [
            {
                title: "Ofrecemos servicio de last mile en la zona de AMBA."
            },
            {
                title: "Realizamos entregas Same Day de paqueteria y big size."
            },
            {
                title: "Ofrecemos servicio de flex."
            }
        ]
    },
    {
        title: '¿Cuál es nuestra cobertura?',
        content: [
            {
                title: "Operamos en todo AMBA."
            },
        ]
    },
    {
        title: '¿Cómo puedo hacer seguimiento de mi envío?',
        content: [
            {
                title: 'Podrés encontrar la información sobre el estado actual y localización de tu pedido en la sección de <a href="/tracking">Seguimiento de envío</a>.'
            },
        ]
    },
    {
        title: '¿Cuáles son los tiempos estimados de entrega?',
        content: [
            {
                title: "Realizamos la 1er visita en las primeras 24 horas hábiles."
            },
            {
                title: "En caso de no poder concretarse, la 2da visita se efectúa 72 horas hábiles después de la primera."
            },
        ]
    },
    {
        title: '¿Como puedo ponerme en contacto con el equipo de soporte?',
        content: [
            {
                title: "Podés contactarte con el equipo de atención por lo siguientes canales:",
                content: [
                    { title: "Webform", url: "#link" },
                    { title: "Chat" }
                ]
            },
        ]
    },
    {
        title: '¿De qué manera me entero cuando hay un cambio de estado del envío?',
        content: [
            {
                title: "Vamos a estar comunicando vía correo electrónico el día anterior a la entrega y el mismo día de entrega sobre el estado actual del envío.",
            },
        ]
    },
    {
        title: '¿Quién puede recibir el pedido?',
        content: [
            {
                title: "Cualquier persona mayor de 18 años con DNI.",
            },
        ]
    },
    {
        title: '¿Qué documentación me van a pedir para entregar el pedido?',
        content: [
            {
                title: "Solo te pediremos el número de DNI y una firma.",
            },
        ]
    },
    {
        title: '¿Qué sucede si no se efectua la primera entrega, como se reprograma?',
        content: [
            {
                title: "Lo replanificamos de manera automática para dentro de las próximas 48hs. Se van a realizar los mismos avisos que en la primera entrega.",
            },
        ]
    },
    {
        title: '¿Qué pasa si no se genera segunda entrega?',
        content: [
            {
                title: "Si no se pudo realizar la segunda entrega el producto es devuelto al comercio y el monto se reintegrará al comprador.",
            },
        ]
    },
    {
        title: '¿Qué documentación necesito para despachar el pedido?',
        content: [
            {
                title: "Solo se necesita la etiqueta impresa.",
            },
        ]
    },
    {
        title: '¿Como tiene que estar preparado el paquete?',
        content: [
            {
                title: 'En el siguiente <a href="https://ayuda.enviopack.com/hc/es-419/articles/360055225072-Packaging-para-tus-paquetes" target="_blank">link</a> encontrará la información detallada sobre cómo debe estar envuelto y preparado el paquete, como así también explicado el correcto pegado de la etiqueta.',
            },
        ]
    },
    {
        title: '¿Puedo cambiar el domicilio de entrega?',
        content: [
            {
                title: "Deberá contactarse con el comprador / e-commerce para hacerlo.",
            },
        ]
    }
]