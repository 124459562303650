import React from 'react';
import './ScreenTransition.scss';

const ScreenTransition = () => {
    return(
        <div className={"ScreenTransition"}>

        </div>
    )
}

export default ScreenTransition;