import { TextField, Typography } from '@mui/material'
import React, { FC } from 'react'
import { ErrorOption } from 'react-hook-form'

import { IMaskInput } from 'react-imask';

import styles from './styles.module.scss';

interface InputProps {
    label?: string
    variant?: string
    register?: any
    type?: React.InputHTMLAttributes<unknown>['type']
    error?: ErrorOption
    multiline?: boolean
    rows?: number
    className?: string
    placeholder?: string
    onChange?: (e: any) => void
    mask?: any
    value?: any
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref: any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="+(00) (00) 0000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange(value)}
                overwrite
            />
        );
    },
);

const Input: FC<InputProps> = ({
    label,
    variant = "filled",
    register,
    type = "text",
    multiline = false,
    rows,
    error,
    className = "",
    placeholder,
    mask = null,
    onChange,
    value,
    ...rest
}) => {
    return (
        <>
            <TextField
                {...rest}
                {...register}
                label={label}
                variant={variant}
                type={type}
                multiline={multiline}
                rows={rows}
                className={`${styles.Input} ${className}`}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                InputProps={mask ? {
                    inputComponent: TextMaskCustom as any,
                } : undefined}
            />
            {error && <Typography variant='caption'>{error.message}</Typography>}
        </>
    )
}

export default Input