export const fonts = {
    h1: {
        fontSize: 'var(--font-h1)',
        fontWeight: 700
    },
    h2: {
        fontSize: 'var(--font-h2)'
    },
    h3: {
        fontSize: 'var(--font-h3)'
    },
    h4: {
        fontSize: 'var(--font-h4)',
        lineHeight: 1.4,
        '@media (max-width: 720px)': { fontSize: 'var(--font-h6)' }
    },
    h5: {
        fontSize: 'var(--font-M)'
    },
    h6: {
        fontSize: 'var(--font-h6)',
        fontFamily: 'var(--font-medium)'
    },
    h1Bold: {
        fontSize: 'var(--font-h2)',
        fontWeight: 700,
    },
    h2Bold: {
        fontSize: 'var(--font-h2)',
        fontWeight: 700,
        '@media (max-width: 600px)': {
            fontSize: '20px'
        }
    },
    h3Bold: {
        fontSize: 'var(--font-h3)',
        fontWeight: 700,
    },
    h5Bold: {
        fontSize: 'var(--font-h5)',
        fontWeight: 700,
    },
    h6Bold: {
        fontSize: 'var(--font-h6)',
        fontWeight: 700,
    },
    subtitle1: { fontWeight: '300', fontSize: 'var(--font-h4)' },
    subtitle2: { fontSize: '16px' },
    body1: { fontFamily: 'var(--font)', fontSize: "16px" },
    body1Bold: {
        fontFamily: 'var(--font)',
        fontSize: "16px",
        fontWeight: 700
    },
    body2: { size: '16px' },
    button: { size: '16px' },
    caption: { size: 'var(--font-caption)' }
}

export interface CustomTypography {
    h1: true
    h2: true
    h3: true
    h4: true
    h5: true
    h6: true
    h1Bold: true
    h2Bold: true
    h3Bold: true
    h5Bold: true
    h6Bold: true
    subtitle1: true
    subtitle2: true
    body1: true
    body1Bold: true
    body2: true
    button: true
    caption: true
}