export const colors = {
  neutral: { white: '#FFF', black: '#000' },
  webBackground: { 100: '#FCFFFA', 200: '#F5F9F2' },
  blue: {
    100: '#FAFBFF',
    200: '#D6DFFF',
    300: '#B8C8FF',
    400: '#99B0FF',
    500: '#2f86a6',
    600: '#5278FF',
    700: 'var(--blue700)'
  },
  green: {
    100: '#F0F3FA',
    200: '#D9E0F2',
    300: '#A4B4DF',
    400: '#738CCE',
    500: 'var(--green500)',
    600: '#263E7D',
    700: 'var(--green700)'
  },
  black: {
    100: '#F1F0FF',
    200: '#E3E0FF',
    300: '#C1BCFF',
    400: '#978FFF',
    500: '#6E5BE8',
    600: '#5643D4',
    700: '#000000',
  },
  red: {
    100: '#FFF4F4',
    200: '#FFCCCC',
    300: '#FF9393',
    400: '#F77A7A',
    500: '#EB5151',
    600: '#E01F1F',
    700: '#CA0000'
  },
  white: {
    100: "FFF",
  },
  yellow: {
    100: "var(--yellow500)",
  },
  infoColor: {
    main: '#00b0f0',
    dark: '#007ba8',
    light: '#04c8f3'
  },
  text: {
    secondary: '#AAACBB',
    disabled: '#CCCDD6'
  },
  success: {
    dark: '#1f9c24',
    light: '#93da96'
  },
  error: {
    main: '#f44336',
    dark: '#e31b0c',
    light: '#f88078'
  },
  warning: {
    main: '#fdb100',
    dark: '#ce9200',
    light: '#ffd777'
  },
}

export interface CustomPalette {
  neutral: { white: string; black: string }
  webBackground: { 100: string; 200: string }
  blue: {
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
  }
  red: {
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
  }
  green: {
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
  }
  black: {
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
  }
  white: {
    100: string
  }
  yellow: {
    100: string
  }
}