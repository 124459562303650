import { ReactElement } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { CarrouselProps } from './types';

const Carrousel = ({
    className = "",
    spaceBetween = 30,
    slidesPerView = 1,
    centeredSlides = true,
    loop = false,
    navigation = true,
    breakpoints = null,
    autoplay = false,
    showArrows = false,
    children
}: CarrouselProps) => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            className={className}
            centeredSlides={centeredSlides}
            {...(navigation ? { pagination: { clickable: true } } : {})}
            navigation={showArrows}
            loop={loop}
            breakpoints={breakpoints}
            autoplay={autoplay ? {
                delay: 2500,
                disableOnInteraction: false,
            } : undefined}
        >
            {
                children.map((item: ReactElement, index: number) => {
                    return <SwiperSlide key={index}> {item} </SwiperSlide>
                })
            }
        </Swiper >

    )
}

export default Carrousel