import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import MainWrapper from '@organisms/MainWrapper';


import styles from './styles.module.scss';
import DropDown from '@molecules/DropDown';
import { FAQ } from './FAQ';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';


const Title = ({ title, url, hasChild = false }: { title: string, url?: string, hasChild?: boolean }) => {
    const Comp = hasChild ? 'li' : 'p'
    return (
        url ? <Comp><Link to={url}>{title}</Link></Comp> : <li dangerouslySetInnerHTML={{__html: title}}></li>
    )
}

const Faq: FC = () => {
    return (
        <MainWrapper
            spacing={6}
            className={styles.Faq}
            container justifyContent='center'
            alignItems="center"
            direction="column"
            hasPadding
        >
            <Grid container direction="column" className={styles.Faq__container} justifyContent="center" >
                <Typography variant="h2Bold" align="center" className={styles.Faq__title}>
                    Resolvé tus dudas con nuestras preguntas frecuentes
                </Typography>
                <Grid container lg={7} xs={12} justifyContent="center" alignContent="center">
                    {FAQ.map((item: any, index: number) => {
                        return (
                            <Grid item sx={{ width: '100%' }} key={index}>
                                <DropDown
                                    title={item.title}
                                >
                                    {item.content.map((subItem: any, index: number) => {
                                        return (
                                            <Box key={index}>
                                                {subItem.content ?
                                                    <Box>
                                                        <Title title={subItem.title} url={subItem.url} />
                                                        <ul>
                                                            {subItem.content.map((subSubItem: any, index: number) =>
                                                                <Title key={index} hasChild title={subSubItem.title} url={subSubItem.url} />
                                                            )}
                                                        </ul>
                                                    </Box>
                                                    :
                                                    <Title title={subItem.title} url={subItem.url} />
                                                }
                                            </Box>
                                        )
                                    })}
                                </DropDown>
                            </Grid>

                        )

                    })}
                </Grid>
            </Grid>
        </MainWrapper >
    )
}

export default Faq