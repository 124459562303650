export interface TrackingContextType {
    trackingData: TrackingDetailData[]
    loading: boolean
    error: string
    transition: boolean
}


export interface TrackingDetailData{
    codigo: TrackingStatus,
    fecha: string,
    mensaje: string
}

export enum TrackingStatus {
    ARTR = 'ARTR',
    COLE = "COLE",
    DESC = "DESC",
    DESP = "DESP",
    DVCU = "DVCU",
    DVFF = "DVFF",
    DVIN = "DVIN",
    DVVE = "DVVE",
    ENTR = "ENTR",
    FPDE = "FPDE",
    FPPP = "FPPP",
    FPRE = "FPRE",
    FRAU = "FRAU",
    FREI = "FREI",
    FRES = "FRES",
    FRID = "FRID",
    FRMD = "FRMD",
    FRPE = "FRPE",
    FSEC = "FSEC",
    FSTF = "FSTF",
    GUAR = "GUAR",
    GUXD = "GUXD",
    INIC = "INIC",
    INTE = "INTE",
    PACT = "PACT",
    PCON = "PCON",
    PDIR = "PDIR",
    PDOM = "PDOM",
    PREC = "PREC",
    PZON = "PZON",
    RCHZ = "RCHZ",
    SINI = "SINI",
    SUCS = "SUCS",
    TRON = "TRON",
}
