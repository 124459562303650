import { createTheme } from '@mui/material/styles';
import { Components } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { cSSVariablesService } from '../../../utils/css-variables';
import { CustomTypography, fonts } from './fonts';
import { colors } from './colors';

import { button } from './components/button';
import { card } from './components/card';
import { textField } from './components/textField';
import { accordion } from './components/accordion';
import { radio } from './components/radioButton';

const fontS = cSSVariablesService.get('--font-h6');

const font = cSSVariablesService.get('--font');

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends CustomTypography { }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Bold: React.CSSProperties
  h2Bold: React.CSSProperties
  h3Bold: React.CSSProperties
  h5Bold: React.CSSProperties
  h6Bold: React.CSSProperties
  body1Bold: React.CSSProperties
}

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 445,
      md: 588,
      lg: 868,
      xl: 1024
    }
  },
  typography: {
    fontFamily: font,
    fontSize: 16,
    htmlFontSize: 16,
    button: {
      textTransform: 'capitalize',
      fontWeight: 600,
      fontSize: fontS
    },
    h1: fonts.h1,
    h2: fonts.h2,
    h3: fonts.h3,
    h4: fonts.h4,
    h5: fonts.h5,
    h6: fonts.h6,
    h1Bold: fonts.h1Bold,
    h2Bold: fonts.h2Bold,
    h3Bold: fonts.h3Bold,
    h5Bold: fonts.h5Bold,
    h6Bold: fonts.h6Bold,
    subtitle1: fonts.subtitle1,
    subtitle2: fonts.subtitle2,
    body1: fonts.body1,
    body1Bold: fonts.body1Bold,
    body2: fonts.body2,
    caption: fonts.caption
  } as ExtendedTypographyOptions,
  palette: {
    background: {
      default: colors.neutral.white
    },
    primary: {
      light: colors.blue[100],
      main: colors.blue[500],
      dark: colors.blue[700],
      contrastText: colors.neutral.white
    },
    secondary: {
      light: colors.blue[500],
      main: colors.blue[500],
      dark: colors.red[700],
      contrastText: colors.neutral.white
    },
    text: {
      primary: colors.black[700],
      secondary: colors.text.secondary,
      disabled: colors.text.disabled
    },
    info: {
      main: colors.infoColor.main,
      dark: colors.infoColor.dark,
      light: colors.infoColor.light,
      contrastText: colors.neutral.white
    },
    success: {
      main: colors.green[700],
      dark: colors.success.dark,
      light: colors.success.light,
      contrastText: colors.neutral.white
    },
    error: {
      main: colors.error.main,
      dark: colors.error.dark,
      light: colors.error.light,
      contrastText: colors.neutral.white
    },
    warning: {
      main: colors.warning.main,
      dark: colors.warning.dark,
      light: colors.warning.light,
      contrastText: colors.neutral.white
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  components: Object.assign(
    button,
    card,
    textField,
    accordion,
    radio
  ) as unknown as Components
});

export default theme;