import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Faq from './components/pages/Faq';
import Tracking from './components/pages/Tracking';
import Nav from './components/UI/organisms/Nav';

import { TrackingProvider } from '@context/index';
import { ThemeProvider } from '@emotion/react';

import theme from '@theme/theme';
import { StyledEngineProvider } from '@mui/material';
import Footer from '@organisms/Footer';
import TrackingDetail from '@pages/TrackingDetail';
import Wrapper from '@organisms/Wrapper';


const NavLayout = ({ reduced = false, children }: { reduced?: boolean, children: JSX.Element }) => {
  return (
    <>
      <Nav reduced={reduced} />
      {children}
    </>
  )
}

function App() {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <TrackingProvider>
          <BrowserRouter>
            <Wrapper>
              <Routes>
                <Route path="/" element={<NavLayout><Home /></NavLayout>} />
                <Route path="/faq" element={<NavLayout><Faq /></NavLayout>} />
                <Route path="/tracking" element={<NavLayout reduced><Tracking /></NavLayout>} />
                <Route path="/tracking/:id" element={<NavLayout reduced><TrackingDetail /></NavLayout>} />
              </Routes>
              <Footer />
            </Wrapper>

          </BrowserRouter>
        </TrackingProvider>

      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
