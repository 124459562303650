import { TrackingContext } from '@context/index';
import ScreenTransition from '@organisms/ScreenTransition';
import { useContext, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Wrapper = ({ children }: any) => {
    const location = useLocation();
    const { state, dispatch } = useContext(TrackingContext)

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
        dispatch({ type: 'SET_TRANSITION', payload: true })

        setTimeout(() => {
            dispatch({ type: 'SET_TRANSITION', payload: false })
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    return (
        state.transition ?
            <ScreenTransition />
            : children

    )
}

export default Wrapper