import { TrackingContextType } from "./types";

export const TrackingReducer = (state: TrackingContextType, action: any) => {
    switch (action.type) {
        case 'SET_TRACKING_DATA':
            return {
                ...state,
                trackingData: action.payload
            }
        case 'SET_CURRENT_STATUS':
            return {
                ...state,
                currentStatus: action.payload
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            }
        case 'SET_TRANSITION':
            return {
                ...state,
                transition: action.payload
            }
        default:
            return state;
    }
}
