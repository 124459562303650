import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography } from '@mui/material';
import Icon from '@atoms/Icon';
import { DropDownProps } from './types';

import styles from './styles.module.scss';

const DropDown = ({ title, children }: DropDownProps) => {
    return (
        <>
            <Accordion variant='outlined' className={styles.Accordion}>
                <AccordionSummary
                    expandIcon={<Icon name="ExpandMore" color="000" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={styles.Accordion__Summary}
                >
                    <Typography className={styles.Accordion__Title}>{title}</Typography>
                </AccordionSummary >
                <AccordionDetails className={styles.Accordion__Details}>
                    <ul>
                        {children}
                    </ul>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default DropDown