declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        secondary: true;
    }
}

export const radio = {
    MuiRadio: {
        styleOverrides: {
            root: {
                padding: 0,
                paddingRight: '.3rem'
            },
        }
    }
}