import Input from '@atoms/Input';
import { Button, Grid, Typography } from '@mui/material';
import MainWrapper from '@organisms/MainWrapper';
import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';


import styles from './styles.module.scss'

const Tracking: FC = () => {
    const [trackingId, setTrackingId] = useState()
    let navigate = useNavigate();

    const handleClick = () => {
        navigate(`/tracking/${trackingId}`)
    }

    return (
        <MainWrapper className={styles.Tracking} hasPadding>
            <Grid container spacing={4} direction="column">
                <Typography variant="h2Bold" align='center'>Sigue el estado de tu paquete</Typography>
                <Typography variant="h6" align='center' className={styles.Tracking__subtitle}>Ingresa el número de orden para que puedas visualizar en qué  <br /> estado se encuentra su entrega</Typography>
            </Grid>
            <Grid container justifyContent="center" alignContent='center'>
                <Grid lg={4} xs={12} item container justifyContent="center">
                    <Input
                        label="Número de orden"
                        className={styles.Input}
                        onChange={(e) => setTrackingId(e.target.value)}
                    />
                    <Button onClick={handleClick} >
                        Buscar
                    </Button >
                </Grid>
            </Grid>
        </MainWrapper>
    )
}

export default Tracking