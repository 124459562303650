declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        secondary: true;
    }
}

export const card = {
    MuiCard: {
        styleOverrides: {
            root: {
                padding: '40px',
                borderRadius: '25px',
                //filter: "drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.16))",
                transition: "300ms all",
                boxShadow: '-1px 0px 19px 5px rgba(0,0,0,0.16)',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                overflow: 'visible',
                '&:hover': {
                    boxShadow: '-1px 0px 19px 5px rgba(0,0,0,0.25)',
                    //filter: "drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25))",
                    transition: "300ms all",
                },
            },
        }
    }
}