import { TrackingStatus } from "@context/types";

export const getTrackingMessage = (status: TrackingStatus) => {
    switch (status) {
        case TrackingStatus.ARTR:
            return 'Arribo localidad';
        case TrackingStatus.COLE:
            return 'Inicio distribución';
        case TrackingStatus.DESC:
            return 'Descargado';
        case TrackingStatus.DESP:
            return 'Despachado';
        case TrackingStatus.DVFF:
            return 'Devuelto a fullpack';
        case TrackingStatus.DVCU:
            return 'Devolución en curso';
        case TrackingStatus.DVIN:
            return 'En devolución';
        case TrackingStatus.DVVE:
            return 'Devuelto a seller';
        case TrackingStatus.ENTR:
            return 'Entregado';
        case TrackingStatus.FPDE:
            return 'Fullpack producto desconocido';
        case TrackingStatus.FPPP:
            return 'Problema con el paquete';
        case TrackingStatus.FPRE:
            return 'Fullpack preparado';
        case TrackingStatus.FRAU:
            return 'Fraude';
        case TrackingStatus.FREI:
            return 'Frenado envío incompleto';
        case TrackingStatus.FRES:
            return 'Frenado envío suspendido';
        case TrackingStatus.FRID:
            return 'Frenado imposible despachar';
        case TrackingStatus.FRMD:
            return 'Frenado mercadería dañada';
        case TrackingStatus.FRPE:
            return 'Frenado remito pendiente';
        case TrackingStatus.FSEC:
            return 'Fullpack stock en camino';
        case TrackingStatus.FSTF:
            return 'Stock faltante';
        case TrackingStatus.GUAR:
            return 'Problema en guarda';
        case TrackingStatus.GUXD:
            return 'En guardería XD';
        case TrackingStatus.INIC:
            return 'Estado inicial';
        case TrackingStatus.INTE:
            return 'Intento entrega';
        case TrackingStatus.PACT:
            return 'Pactado';
        case TrackingStatus.PCON:
            return 'Problema con contacto';
        case TrackingStatus.PDIR:
            return 'Problema dirección';
        case TrackingStatus.PDOM:
            return 'Problema no contesta';
        case TrackingStatus.PREC:
            return 'Pre colectado';
        case TrackingStatus.PZON:
            return 'Problema zona';
        case TrackingStatus.RCHZ:
            return 'Rechazado';
        case TrackingStatus.SINI:
            return 'Siniestrado';
        case TrackingStatus.SUCS:
            return 'Arribado a sucursal';
        case TrackingStatus.TRON:
            return 'Viaje troncal';
        default:
            return status;
    }
}