import { CircularProgress, Grid } from '@mui/material';
import { MainWrapperProps } from './types';

import styles from './styles.module.scss';

const MainWrapper = ({
    children,
    hasPadding,
    className,
    loading,
    ...rest
}: MainWrapperProps) => {
    return (
        <Grid
            {...rest}
            className={`${styles.MainWrapper} ${hasPadding ? styles.hasPadding : ""} ${className ? className : ""}`}
            component="section"
        >
            {
                loading ?
                    <CircularProgress />
                    : children
            }
        </Grid>
    )
}

export default MainWrapper
